import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import config from '../configuration/config';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const isValidName = (name) => {
  // Validate name: minimum 2 characters, no special characters, no numeric values, no consecutive spaces
  const nameRegex = /^[a-zA-Z]{2,}(?: [a-zA-Z]{2,})*$/;
  return nameRegex.test(name);
};

const isValidUsername = (username) => {
  // Validate username: minimum 2 characters, no consecutive spaces
  const usernameRegex = /^[^\s]{2,}$/;
  return usernameRegex.test(username);
};

const Update = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [isUsernameExists, setIsUsernameExists] = useState(false);

  const [updatedData, setUpdatedData] = useState({
    name: '',
    username: '',
    // ... other fields
  });

  const [originalData, setOriginalData] = useState({
    name: '',
    username: '',
    // ... other fields
  });

  const [showModal, setShowModal] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(config.apiUrl + '/' + userId);
        if (response.ok) {
          const userData = await response.json();
          setUser(userData);

          // Save the original data
          setOriginalData({
            name: userData.name,
            username: userData.username,
            // ... other fields
          });

          // Auto-populate fields for the user to update
          setUpdatedData({
            name: userData.name,
            username: userData.username,
            // ... other fields
          });
        } else {
          console.error('Failed to fetch user data');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [userId]);

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const usersResponse = await fetch(config.apiUrl);
        const usersData = await usersResponse.json();

        // Exclude the current user from the check
        const otherUsers = usersData.filter((user) => user._id !== userId);

        // Check if the updated username already exists among other users
        const isUsernameAlreadyExists = otherUsers.some((user) => user.username === updatedData.username);

        setIsUsernameExists(isUsernameAlreadyExists);
      } catch (error) {
        console.error('Error fetching all users:', error);
      }
    };

    // Fetch all users only if the username is updated
    if (updatedData.username !== originalData.username) {
      fetchAllUsers();
    }
  }, [userId, updatedData.username, originalData.username]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    // Enable or disable the submit button based on validation and changes
    setIsSubmitDisabled(
      !isValidName(updatedData.name) || !isValidUsername(updatedData.username) || JSON.stringify(updatedData) === JSON.stringify(originalData)
    );
  }, [updatedData, originalData]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidName(updatedData.name) || !isValidUsername(updatedData.username)) {
      setError('Invalid name or username format');
      return;
    }

    // Check if there are changes before submitting
    if (JSON.stringify(updatedData) === JSON.stringify(originalData)) {
      setError('No changes made');
      return;
    }

    try {
      const response = await fetch(config.apiUrl + '/' + userId, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });

      if (response.ok) {
        console.log('User updated successfully');
        handleOpenModal();
      } else {
        setError('Failed to update user');
      }
    } catch (error) {
      setError('Error updating user');
      console.error('Error updating user:', error);
    }
  };

  const handleModalConfirmation = () => {
    handleCloseModal();
    navigate('/getUsers');
  };

  return (
    <div className='wrapper-update-form'>
      <div className='wrapper-update-form-content'>
        {user && (
          <>
            <p>Email: {user.email}</p>
            <p>Name: {user.name}</p>

            <form onSubmit={handleSubmit}>
              <label>
                Edit name:
                <input
                  type='text'
                  maxLength={50}
                  name='name'
                  value={updatedData.name}
                  onChange={handleChange}
                />
              </label>

              <label>
                Edit Username:
                <input
                  maxLength={20}
                  type='text'
                  name='username'
                  value={updatedData.username}
                  onChange={handleChange}
                />
              </label>

              <button type='submit' className='submit-button' disabled={isSubmitDisabled || isUsernameExists}>
                Submit
              </button>

              {error && <div className='error-message'>{error}</div>}
            </form>
          </>
        )}

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>User Updated</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            User data has been updated successfully!
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleModalConfirmation}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export { Update };
