import React, { useState } from 'react';
import validator from 'validator';

const Email = () => {
  const [emailInput, setEmailInput] = useState('');
  const [emailArray, setEmailArray] = useState([]);
  const [validEmails, setValidEmails] = useState([]);
  const [invalidEmails, setInvalidEmails] = useState([]);
  const [validCount, setValidCount] = useState(0);
  const [invalidCount, setInvalidCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const handleInputChange = (e) => {
    setEmailInput(e.target.value);
  };

  const isValidEmail = (email) => {
    // Additional check using regex for email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return validator.isEmail(email) && emailRegex.test(email);
  };

  const categorizeEmails = () => {
    const emails = emailInput.split(',').map((email) => email.trim());
    const valid = [];
    const invalid = [];

    for (const email of emails) {
      if (isValidEmail(email)) {
        valid.push(email);
      } else {
        invalid.push(email);
      }
    }

    setEmailArray(emails);
    setValidEmails(valid);
    setInvalidEmails(invalid);

    setValidCount(valid.length);
    setInvalidCount(invalid.length);
    setTotalCount(emails.length);
  };

  return (
    <div className="email-container">
      <h2>Email Validation Results</h2>

      <textarea
        className="email-textarea"
        placeholder="Enter comma-separated emails"
        value={emailInput}
        onChange={handleInputChange}
      />

      <button className="email-button" onClick={categorizeEmails}>
        Categorize Emails
      </button>

      <div className="email-lists-container">
        <div className="email-list">
          <h3>Valid Emails <p className="count-text">Valid Count: {validCount}</p> </h3>
          <ul className="email-list-ul valid-list">
            {validEmails.map((email, index) => (
              <li key={index}>{email}</li>
            ))}
          </ul>
          
        </div>

        <div className="email-list">
          <h3>Invalid Emails <p className="count-text">Invalid Count: {invalidCount}</p> </h3>
          <ul className="email-list-ul invalid-list">
            {invalidEmails.map((email, index) => (
              <li key={index}>{email}</li>
            ))}
          </ul>
          
        </div>

        <div className="email-list">
          <h3>Email List <p className="count-text">Total Count: {totalCount}</p> </h3>
          <ul className="email-list-ul total-list">
            {emailArray.map((email, index) => (
              <li key={index}>{email}</li>
            ))}
          </ul>
          
        </div>
      </div>
    </div>
  );
};

export { Email };
