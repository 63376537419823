import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const Delete = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`https://backend.poc.optiorx.com/users/${userId}`);
        if (response.ok) {
          const userData = await response.json();
          setUser(userData);
        } else {
          alert('Failed to fetch user data');
        }
      } catch (error) {
        alert('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [userId]);

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short',
    };

    return new Date(dateString).toLocaleString('en-US', options);
  };

  const handleDelete = () => {
    setShowModal(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await fetch(`https://backend.poc.optiorx.com/users/${userId}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        // Handle success, e.g., redirect or show a success message
        navigate('/getUsers');
      } else {
        alert('Failed to delete user');
        // Handle error, e.g., show an error message to the user
      }
    } catch (error) {
      alert('Error deleting user:', error);
    } finally {
      setShowModal(false); // Close the modal after deletion or error
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className='wrapper-update-form'>
      <div className='wrapper-update-form-content'>
        {user && (
          <div className="tableContainer">
            <table className="userTable">
              <tbody>
                <tr>
                  <td>Email</td>
                  <td>{user.email}</td>
                </tr>
                <tr>
                  <td>User ID</td>
                  <td>{user.id}</td>
                </tr>
                <tr>
                  <td>Name</td>
                  <td>{user.name}</td>
                </tr>
                <tr>
                  <td>Last Updated</td>
                  <td>{user.time_updated ? formatDate(user.time_updated) : 'No Changes made yet'}</td>
                </tr>
                <tr>
                  <td>User Created</td>
                  <td>{formatDate(user.time_created)}</td>
                </tr>
                <tr>
                  <td>User Name</td>
                  <td>{user.username}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        {/* Delete button */}
        <div className='button-flex'>
          <button className='delete-button' onClick={handleDelete}>
            Delete User
          </button>
        </div>

        {/* Modal for confirmation */}
        <Modal show={showModal} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this user?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={confirmDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export { Delete };
