import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../configuration/config';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const Contacts = () => {
  const [formData, setFormData] = useState({
    name: '',
    username: '',
    email: '',
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    username: '',
    email: '',
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [responseTime, setResponseTime] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isDuplicateUser, setIsDuplicateUser] = useState(false);
  const [isUsernameValid, setIsUsernameValid] = useState(true);

  useEffect(() => {
    setIsDuplicateUser(false);
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    let test = false;
    if (name === "email") {
      test = isValidEmail(value);
    } else if (name === "username") {
      test = isValidUsername(value);
      setIsUsernameValid(test);
    } else {
      test = isValidName(value);
    }
    if (!test)
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `${name} is not valid`,
      }));
    else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const isValidUsername = (username) => {
    const usernameRegex = /^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\/-]+$/;
    return usernameRegex.test(username);
  };

  const isValidName = (name) => {
    const nameRegex = /^[a-zA-Z]{2,}(?: [a-zA-Z]{2,})*$/;
    return nameRegex.test(name);
  };

  const isFormValid = () => {
    return (
      Object.values(formData).every((value) => value !== '') &&
      isValidEmail(formData.email) &&
      isValidName(formData.name) &&
      isUsernameValid
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isValid = true;
    const newFormErrors = { ...formErrors };

    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        newFormErrors[key] = 'This field is required';
        isValid = false;
      }
    });

    if (!isValidEmail(formData.email)) {
      newFormErrors.email = 'Invalid email format';
      isValid = false;
    }

    if (!isValidName(formData.name)) {
      newFormErrors.name = 'Invalid name format';
      isValid = false;
    }

    if (!isValid) {
      setFormErrors(newFormErrors);
      return;
    }

    try {
      const checkEmailResponse = await axios.get(config.apiUrl);

      const isEmailAlreadyExists = checkEmailResponse.data.some((user) => user.email === formData.email);
      const isUsernameAlreadyExists = checkEmailResponse.data.some((user) => user.username === formData.username);


      if (isEmailAlreadyExists || isUsernameAlreadyExists) {
        setIsDuplicateUser(true);
        return;
      }

      const startTime = performance.now();

       await axios.post(config.apiUrl + '/', formData, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      });

      const endTime = performance.now();
      const timeTaken = endTime - startTime;
      setResponseTime(timeTaken.toFixed(2));

      handleOpenModal();

      setFormData({
        name: '',
        username: '',
        email: '',
      });

      setTimeout(() => {
        setResponseTime(0);
      }, 5000);
    } catch (error) {
      console.error('Error creating user:', error.message);
      setErrorMessage('Failed to submit form. Please try again later.');
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className='wrapper-update-form'>
      <div className='wrapper-update-form-content'>
        <div className="form-container">
          {responseTime > 0 && <p>API Response Time: {responseTime} milliseconds</p>}
          <h2>Create User</h2>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          {isDuplicateUser && <div className="error-message">User with this email or username already exists.</div>}
          <form onSubmit={handleSubmit}>
            <div className="form-field">
              <label>
                Name:*
                <input type="text" name="name" maxLength={50} value={formData.name} onChange={handleInputChange} />
              </label>
              <span className="error-message">{formErrors.name}</span>
            </div>
            <div className="form-field">
              <label>
                Username:*
                <input
                  type="text"
                  name="username"
                  maxLength={20}
                  value={formData.username}
                  onChange={handleInputChange}
                />
              </label>
              <span className="error-message">{formErrors.username}</span>
            </div>
            <div className="form-field">
              <label>
                Email:*
                <input type="text" name="email" value={formData.email} maxLength={50} onChange={handleInputChange} />
              </label>
              <span className="error-message">{formErrors.email}</span>
            </div>
            <button
              className="custom-create-button"
              type="submit"
              disabled={!isFormValid() || isDuplicateUser || !isUsernameValid}
            >
              Create User
            </button>
          </form>
        </div>

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>User Created</Modal.Title>
          </Modal.Header>
          <Modal.Body>User has been created successfully!</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Contacts;
