import React from 'react';
import optiologo from '../assets/images/optiologo.png'; // Adjust the path accordingly

const Home = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <img alt='Hello' src={optiologo} />
      <h1>Development is in Progress
      
      </h1>
      <div> <a href="https://qa.optiorx.com/" target="_blank" rel="noopener noreferrer">
    Click here
  </a> </div>
      {/* <p>This page is under construction. Please check back later.</p> */}
    </div>
  );
};

export { Home };