import React, { useEffect, useState } from 'react';
import config from '../configuration/config';
import { Link } from 'react-router-dom';

const About = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [responseTime, setResponseTime] = useState(null);

  const tableHeaderStyle = {
    padding: '12px',
    textAlign: 'left',
    border: '1px solid #ddd',
  };

  const tableCellStyle = {
    padding: '8px',
    textAlign: 'left',
    border: '1px solid #ddd',
  };

  useEffect(() => {
    console.log("9:54 pm , 12/27/2023 ");
    const fetchData = async () => {
      try {
        const startTime = performance.now();

        const response = await fetch(config.apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
        });

        const responseData = await response.json();
        const users = responseData || [];
 
        setData(users);

        const endTime = performance.now();
        const timeTaken = endTime - startTime;
        setResponseTime(timeTaken.toFixed(2)); // Round to two decimal places
        setTimeout(() => {
          setResponseTime(0); // Round to two decimal places
        }, 5000);
      } catch (error) {
        setError(error);
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>ERROR: Please check if the backend is up!</p>;

  return (
    <div  >
      <h1>Users Available</h1>
      {
        responseTime > 0 && <p>API Response Time: {responseTime} milliseconds</p>
      }
      <table style={{ width: '99%', borderCollapse: 'collapse', margin: 'auto', marginTop: '20px' }}>
        <thead style={{ backgroundColor: '#f2f2f2' }}>
          <tr>
            <th style={tableHeaderStyle}>ID</th>
            <th style={tableHeaderStyle}>Name</th>
            <th style={tableHeaderStyle}>Username</th>
            <th style={tableHeaderStyle}>Email</th>
            <th style={tableHeaderStyle}>Created At</th>
            <th style={tableHeaderStyle}>Last Updated</th>
            <th style={tableHeaderStyle}>Actions </th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((user, index) => (
              <tr key={user.id}>
                <td style={tableCellStyle}>{index + 1}</td>
                <td style={tableCellStyle}>{user.name}</td>
                <td style={tableCellStyle}>{user.username}</td>
                <td style={tableCellStyle}>{user.email}</td>
                <td style={tableCellStyle}>
                  {new Date(user.time_created).toLocaleString(undefined, {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    hour12: true,
                  })}
                </td>
                <td style={tableCellStyle}>
                  {user.time_updated ? (
                    new Date(user.time_updated).toLocaleString(undefined, {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      second: 'numeric',
                      hour12: true,
                    })
                  ) : (
                    'No changes made yet'
                  )}
                </td>

                <td style={{
                  ...tableCellStyle, display: "flex",
                  gap: "4px"
                }} >
                  <Link to={`/updateUser/${user.id}`}>
                    <button className="custom-update-button" >Update</button>
                  </Link>
                  <Link to={`/deleteUser/${user.id}`}>
                    <button className="custom-delete-button" >Delete</button>
                  </Link>

                  {/*  */}


                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" style={tableCellStyle}>
                No users created yet
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default About;
