import Contacts from "./components/Contacts";
import About from "./components/About";
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Email } from './components/Email'
import { Home } from './components/Home'
import { Update } from './components/Update'
import { Delete } from './components/Delete'
import optiologo from './assets/images/white-nobg.png';
// import Sample from "components/Sample";
function App() {
  return (
    <div>


      <Router>
        {/* <Sample/> */}
        <nav className="navbar">
          <ul className="nav-list" style={{ alignItems: "center", display: "flex" }}>
          <li className="nav-item">
              <Link to="/" className="navbar-brand">
              <img src={optiologo} alt="" style={{ objectFit: "contain",  maxWidth: "9em" }} />

              </Link>
            </li>
            {/* <a className="navbar-brand" >
              <img src={optiologo} alt="" style={{ objectFit: "contain",  maxWidth: "9em" }} />
            </a> */}
            <li className="nav-item">
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/getUsers" className="nav-link">
                Get Users
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/createUser" className="nav-link">
                Create User
              </Link>
            </li>
            {/*  */}
            {/* <li className="nav-item">
            <Link to="/updateUser" className="nav-link">
            updateUser
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/deleteUser" className="nav-link">
            deleteUser
            </Link>
          </li> */}
            {/* <li className="nav-item">
            <Link to="/email" className="nav-link">
              Email
            </Link>
          </li> */}
          </ul>
        </nav>
        <Routes>
          <Route path="/getUsers" element={<About />} />
          <Route path="/createUser" element={<Contacts />} />
          <Route path="/email" element={<Email />} />
          <Route path="/updateUser/:userId" element={<Update />} />
          <Route path="/deleteUser/:userId" element={<Delete />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

